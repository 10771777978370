import Vue from 'vue'
import VueRouter from 'vue-router'

import VueMeta from 'vue-meta'
//import store from '@/state/store'

import routes from './routes'
import { EncryptStorage } from 'encrypt-storage';
import appConfig from "@/app.config";
export const encryptStorage = new EncryptStorage('secret-key-value', {
  prefix: appConfig.prefix,
});


Vue.use(VueRouter)
Vue.use(VueMeta, {
    // The component option name that vue-meta looks for meta info on.
    keyName: 'page',
})

const router = new VueRouter({
    routes,
    // Use the HTML5 history API (i.e. normal-looking routes)
    // instead of routes with hashes (e.g. example.com/#/about).
    // This may require some server configuration in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    },
})

router.beforeEach((to, from, next) => {
    var token = encryptStorage.getItem('token')
    if(to.matched.some(record => record.meta.authRequired)) {
        if (token == null) {
            console.log(token);
            //store.commit('savePreviosLink', to.path);
            encryptStorage.setItem('PL',  to.path);
            console.log(to.path);
            next({
                path: '/login',
                //params: { nextUrl: to.fullPath }
            })
        } else {
            if(to.matched.some(record => record.meta.is_admin)) {
                if (localStorage.getItem('masteradmin') != null) {
                    if (localStorage.getItem('masteradmin') == 'true') {
                        //console.log("admin")
                        next()
                    }else{
                        //console.log("admin1")
                        next({
                            path: '/dashboard',
                            //params: { nextUrl: to.fullPath }
                        })
                    }
                }else{
                    //console.log("admin2")
                    next({
                        path: '/home',
                        //params: { nextUrl: to.fullPath }
                    })
                }
            }else {
                next()
            }
        }
    } else if(to.matched.some(record => record.meta.guest)) {
        if(token){
            next({
                path: '/home',
            })
        }
        else{
            next()
        }
    }else if(to.matched.some(record => record.meta.landing)) {
        const landing = sessionStorage.getItem("landing");
        console.log(landing)
        if(landing){
            next({
                path: '/home',
            })
        }
        else{
            if (token == null) {
                next()
            }else{
                next({
                    path: '/home',
                })
            }
        }
    }
    else {
        next()
    }
})
export default router