export default [
  
   {
    path: '/home',
    name: 'home',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/easycash/index')
  },
  {
    path: '/',
    name: 'landing',
    meta: {
      landing: true,
    },
    component: () => import('../views/pages/easycash/landing')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      guest: true,
    },
    component: () => import('../views/pages/easycash/login')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      guest: true,
    },
    component: () => import('../views/pages/easycash/create_account')
    //component: () => import('../views/pages/easycash/register')
  },
  {
    path: '/create-account',
    name: 'create-account',
    meta: {
      guest: true,
    },
    component: () => import('../views/pages/easycash/create_account')
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/easycash/logout')
  },
  {
    path: '/bill',
    name: 'bill',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/easycash/bill')
  },
  {
    path: '/loan-application/:id/loan-detail',
    name: 'loan detail',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/easycash/loan_detail')
  },
  {
    path: '/apply-loan',
    name: 'apply loan',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/easycash/apply_loan')
  },
  {
    path: '/support',
    name: 'support',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/easycash/support')
  },
  {
    path: '/me',
    name: 'me',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/easycash/me')
  },
  {
    path: '/my-profile',
    name: 'my profile',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/easycash/my_profile')
  },
  {
    path: '/my-profile/identity-information',
    name: 'identity information',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/easycash/profile/identity_information')
  },
  {
    path: '/my-profile/data-information',
    name: 'data information',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/easycash/profile/data_information')
  },
  {
    path: '/my-profile/bank-information',
    name: 'bank information',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/easycash/profile/bank_information')
  },
  {
    path: '/language',
    name: 'change language',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/easycash/change_language')
  },
  // {
  //   path: '/test',
  //   name: 'test',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/easycash/starter')
  // },
  // {
  //   path: '/calendar',
  //   name: 'calendar',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/calendar/index')
  // },
  // {
  //   path: '/chat',
  //   name: 'chat',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/chat/index')
  // },
  // {
  //   path: '/ecommerce/products',
  //   name: 'Products',
  //   meta: {
  //     authRequired: true
  //   },
  //   component: () => import('../views/pages/ecommerce/products')
  // },
  // {
  //   path: '/ecommerce/product-detail/:id',
  //   name: 'Product Detail',
  //   meta: {
  //     authRequired: true
  //   },
  //   component: () => import('../views/pages/ecommerce/product-detail')
  // },
  // {
  //   path: '/ecommerce/orders',
  //   name: 'Orders',
  //   meta: {
  //     authRequired: true
  //   },
  //   component: () => import('../views/pages/ecommerce/orders')
  // },
  // {
  //   path: '/ecommerce/customers',
  //   name: 'Customers',
  //   meta: {
  //     authRequired: true
  //   },
  //   component: () => import('../views/pages/ecommerce/customers')
  // },
  // {
  //   path: '/ecommerce/cart',
  //   name: 'Cart',
  //   meta: {
  //     authRequired: true
  //   },
  //   component: () => import('../views/pages/ecommerce/cart')
  // },
  // {
  //   path: '/ecommerce/checkout',
  //   name: 'Checkout',
  //   meta: {
  //     authRequired: true
  //   },
  //   component: () => import('../views/pages/ecommerce/checkout')
  // },
  // {
  //   path: '/ecommerce/shops',
  //   name: 'Shops',
  //   meta: {
  //     authRequired: true
  //   },
  //   component: () => import('../views/pages/ecommerce/shops')
  // },
  // {
  //   path: '/ecommerce/add-product',
  //   name: 'Add Product',
  //   meta: {
  //     authRequired: true
  //   },
  //   component: () => import('../views/pages/ecommerce/add-product')
  // },
  // {
  //   path: '/email/inbox',
  //   name: 'Inbox',
  //   meta: {
  //     authRequired: true
  //   },
  //   component: () => import('../views/pages/email/inbox')
  // },
  // {
  //   path: '/email/reademail/:id',
  //   name: 'Read Email',
  //   meta: {
  //     authRequired: true
  //   },
  //   component: () => import('../views/pages/email/reademail')
  // },
  // {
  //   path: '/invoices/detail',
  //   name: 'Invoice Detail',
  //   meta: {
  //     authRequired: true
  //   },
  //   component: () => import('../views/pages/invoices/detail')
  // },
  // {
  //   path: '/invoices/list',
  //   name: 'Invoice List',
  //   meta: {
  //     authRequired: true
  //   },
  //   component: () => import('../views/pages/invoices/list')
  // },
  // {
  //   path: '/contacts/grid',
  //   name: 'User Grid',
  //   meta: {
  //     authRequired: true
  //   },
  //   component: () => import('../views/pages/contacts/grid')
  // },
  // {
  //   path: '/contacts/list',
  //   name: 'User List',
  //   meta: {
  //     authRequired: true
  //   },
  //   component: () => import('../views/pages/contacts/list')
  // },
  // {
  //   path: '/contacts/profile',
  //   name: 'Profile',
  //   meta: {
  //     authRequired: true
  //   },
  //   component: () => import('../views/pages/contacts/profile')
  // },
  // {
  //   path: '/utility/starter',
  //   name: 'Starter-page',
  //   meta: {
  //     authRequired: true
  //   },
  //   component: () => import('../views/pages/utility/starter')
  // },
  // {
  //   path: '/utility/maintenance',
  //   name: 'maintenance',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/utility/maintenance')
  // },
  // {
  //   path: '/utility/comingsoon',
  //   name: 'comingsoon',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/utility/comingsoon')
  // },
  // {
  //   path: '/utility/timeline',
  //   name: 'timeline',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/utility/timeline')
  // },
  // {
  //   path: '/utility/faqs',
  //   name: 'faqs',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/utility/faqs')
  // },
  // {
  //   path: '/utility/pricing',
  //   name: 'pricing',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/utility/pricing')
  // },
  // {
  //   path: '/utility/404',
  //   name: 'error-404',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/utility/404')
  // },
  // {
  //   path: '/utility/500',
  //   name: 'error-500',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/utility/500')
  // },
  // {
  //   path: '/form/elements',
  //   name: 'elements',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/form/elements')
  // },
  // {
  //   path: '/form/validation',
  //   name: 'validation',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/form/validation')
  // },
  // {
  //   path: '/form/advanced',
  //   name: 'form-advanced',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/form/advanced')
  // },
  // {
  //   path: '/form/editor',
  //   name: 'editor',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/form/editor')
  // },
  // {
  //   path: '/form/upload',
  //   name: 'upload',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/form/upload')
  // },
  // {
  //   path: '/form/repeater',
  //   name: 'repeater',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/form/repeater')
  // },
  // {
  //   path: '/form/wizard',
  //   name: 'wizard',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/form/wizard')
  // },
  // {
  //   path: '/form/mask',
  //   name: 'mask',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/form/mask')
  // },
  // {
  //   path: '/tables/basic',
  //   name: 'basic-table',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/tables/basic')
  // },
  // {
  //   path: '/tables/advanced',
  //   name: 'advanced',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/tables/advanced')
  // },
  // {
  //   path: '/charts/apex',
  //   name: 'apex',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/charts/apex/index')
  // },
  // {
  //   path: '/charts/chartist',
  //   name: 'chartist',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/charts/chartist/index')
  // },
  // {
  //   path: '/charts/chartjs',
  //   name: 'chartjs',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/charts/chartjs/index')
  // },
  // {
  //   path: '/charts/echart',
  //   name: 'echart',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/charts/echart/index')
  // },
  // {
  //   path: '/icons/unicons',
  //   name: 'unicons',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/icons/unicons')
  // },
  // {
  //   path: '/icons/boxicons',
  //   name: 'boxicons',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/icons/boxicons')
  // },
  // {
  //   path: '/icons/materialdesign',
  //   name: 'materialdesign',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/icons/materialdesign')
  // },
  // {
  //   path: '/icons/dripicons',
  //   name: 'dripicons',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/icons/dripicons')
  // },
  // {
  //   path: '/icons/fontawesome',
  //   name: 'fontawesome',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/icons/fontawesome')
  // },
  // {
  //   path: '/maps/google',
  //   name: 'google',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/maps/google')
  // },
  // {
  //   path: '/maps/leaflet',
  //   name: 'leaflet',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/maps/leaflet')
  // },
  // {
  //   path: '/ui/alerts',
  //   name: 'alerts',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/ui/alerts')
  // },
  // {
  //   path: '/ui/buttons',
  //   name: 'buttons',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/ui/buttons')
  // },
  // {
  //   path: '/ui/cards',
  //   name: 'cards',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/ui/cards')
  // }, {
  //   path: '/ui/carousel',
  //   name: 'carousel',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/ui/carousel')
  // }, {
  //   path: '/ui/dropdown',
  //   name: 'dropdown',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/ui/dropdown')
  // }, {
  //   path: '/ui/grid',
  //   name: 'grid',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/ui/grid')
  // }, {
  //   path: '/ui/images',
  //   name: 'images',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/ui/images')
  // }, {
  //   path: '/ui/lightbox',
  //   name: 'lightbox',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/ui/lightbox')
  // },
  // {
  //   path: '/ui/modals',
  //   name: 'modals',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/ui/modals')
  // },
  // {
  //   path: '/ui/rangeslider',
  //   name: 'rangeslider',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/ui/rangeslider')
  // },
  // {
  //   path: '/ui/progressbar',
  //   name: 'progressbar',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/ui/progressbar')
  // },
  // {
  //   path: '/ui/placeholder',
  //   name: 'placeholder',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/ui/placeholder')
  // },
  // {
  //   path: '/ui/sweet-alert',
  //   name: 'sweet-alert',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/ui/sweet-alert')
  // },

  // {
  //   path: '/ui/tabs-accordions',
  //   name: 'tabs-accordions',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/ui/tabs-accordions')
  // },
  // {
  //   path: '/ui/typography',
  //   name: 'typography',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/ui/typography')
  // },

  // {
  //   path: '/ui/video',
  //   name: 'video',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/ui/video')
  // },
  // {
  //   path: '/ui/general',
  //   name: 'general',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/ui/general')
  // },
  // {
  //   path: '/ui/colors',
  //   name: 'colors',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/ui/colors')
  // },
  // {
  //   path: '/ui/rating',
  //   name: 'rating',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/ui/rating')
  // },
  // {
  //   path: '/auth/login-1',
  //   name: 'login-1',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/auth/login-1')
  // },
  // {
  //   path: '/auth/register-1',
  //   name: 'register-1',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/auth/register-1')
  // },{
  //   path: '/auth/lock-screen',
  //   name: 'lock-screen',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/auth/lock-screen')
  // },{
  //   path: '/auth/recoverpwd',
  //   name: 'recoverpwd',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../views/pages/auth/recoverpwd')
  // },
  {
    path: '/reset',
    name: 'Reset',
    meta: {
    },
    beforeEnter(){
      localStorage.clear()
      sessionStorage.clear()
      window.location.href = '/'
    }
  },
  {
      path: '/404',
      name: 'Error 404',
      meta: {
          guest: true,
          authRequired: true,
      },
      component: () =>
          import ('../views/pages/easycash/404')
  },
  {
      path: "/:pathMatch(.*)*",
      redirect: "/404",
  }
  
]
